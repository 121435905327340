export const PATH_IMAGES = '/assets/images/'
export const PATH_IMAGES_ICON = '/assets/images/icon/'
export const PATH_BRAND = '/assets/brand/'
export const PATH_IMG_DEPOSIT = `${PATH_IMAGES}components/desktop/pages/account/deposit/`
export const PATH_IMG_WITH_DRAW = `${PATH_IMAGES}components/desktop/pages/account/withdraw/`
export const PATH_GAME_URL = `${PATH_IMAGES}components/game/`
export const PATH_GAME_MB_URL = `${PATH_IMAGES}components/mobile/game/`
export const PATH_AVATAR_URL = `${PATH_IMAGES}components/desktop/avatar/`
export const PATH_RANK_URL = `${PATH_IMAGES}components/desktop/ranks/`
export const PATH_ACCOUNT_URL = `${PATH_IMAGES}components/desktop/account/`
export const PATH_BANK_ICON_URL = `${PATH_IMAGES}components/desktop/banks/`

export const PATH_BANK_URL = `${PATH_IMAGES}components/common/banks/`
export const PATH_LOGO_BANK = `${PATH_IMAGES}components/desktop/banks/`
export const PATH_CODEPAY_BANK_URL = `${PATH_IMAGES}components/common/banks/codepay-banks/`

export const PATH_IMG_SPORT = `${PATH_IMAGES}sports/`
export const PATH_IMG_SPORT_SCHEDULE = `${PATH_IMAGES}components/desktop/pages/lich-thi-dau/sport-schedule/`
export const PATH_IMG_SECTION_SCHEDULE = `${PATH_IMAGES}components/desktop/pages/lich-thi-dau/section-schedule/`
export const PATH_ICON_URL = `${PATH_IMAGES}icon/`
export const PATH_ICON_HOT_URL = `${PATH_IMAGES}common/`
